body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.h-85 {
  height: 85vh;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer-paper-container {
  padding: 30px;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.sgpa-paper-container {
  padding: 1rem;
  padding-bottom: 0.2rem;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

/* for larger screens media query */
@media (min-width: 768px) {
  .sgpa-paper-container {
    padding: 30px;
  }
}
